$background-theme: #1E1E26;

@media screen and (max-width: 920px) {
    .container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.page-container {
    background-color: $background-theme;
    margin-top: 63px;
}

section {
    background-color: transparent !important;
    padding: 80px 0;

    @media screen and (max-width: 1199px) {
        padding: 50px 0;
    }
}

#inner-content {
    // background-color: $background-theme;
    padding: 20px;
}

.short-content-wrapper {
    min-height: 700px;
}

.section-wrapper {
    max-width: 600px;
    margin: 1rem auto;
}

.img-wrapper {
    max-width: 600px;
    margin: 1rem auto;
}

.img-wrapper-sm {
    max-width: 400px;
    margin: 1rem auto;
}

.img-wrapper-xs {
    max-width: 300px;
    margin: 1rem auto;
}

.video-container {
    position:relative;
    padding-bottom:56.25%;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

// Display none in XL
.d-none-xl {
    @media screen and (min-width: 1200px) {
        display: none;
    }
}

// Display none start from SM
.d-none-from-sm {
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

// Background
.curve-line-background {
    background-repeat: no-repeat;
    background-image: url('assets/images/common/curve-line-background.png');
    background-size: 100%;
    background-position: top right;
    min-height: 500px;
}

// .full-page-background {
    // background-image: url('assets/images/common/full-page-background.jpg');
    // background-size: 100%;
    // background-position: top right;
// }

.short-page-background {
    background-repeat: no-repeat;
    background-image: url('assets/images/common/short-page-background.png');
    background-size: 100%;
    background-position: top left;
}

.blue-right-background {
    background-repeat: no-repeat;
    background-image: url('assets/images/common/blue-right-background.png');
    background-size: 100%;
    background-position: right;
}

.card-bg {
    background-color: #262634;
    border-radius: 10px;
    padding: 2rem;
    color: white;
}

// Text Colour
.text-white-1 {
    color: white !important;
    opacity: 0.8;
}

.text-white-2 {
    color: white !important;
    opacity: 0.65;
}

.text-white-3 {
  color: white !important;
  opacity: 0.4;
}

// Wrapper

.wrapper-2xl {
  max-width: 1300px;
  margin: 0 auto;
}

.wrapper-xl {
    max-width: 1200px;
    margin: 0 auto;
}

.wrapper-lg {
    max-width: 1100px;
    margin: 0 auto;
}

.wrapper-base {
    max-width: 900px;
    margin: 0 auto;
}

.wrapper-md {
    max-width: 750px;
    margin: 0 auto;
}

.wrapper-sm {
    max-width: 600px;
    margin: 0 auto;
}

.wrapper-xs {
    max-width: 400px;
    margin: 0 auto;
}

// Padding
.pt-11 {
    padding-top: 11rem !important;
    @media screen and (max-width: 767px) {
        padding-top: 5rem !important;
    }
}

.wrapper-img {
    margin-bottom: 20px !important;
    @media screen and (max-width: 575px) {
        img {
            max-width: 250px !important;
            margin: auto;
        }
    }
}

@media screen and (max-width: 1299px) {
  h1 {
    font-size: 65px;
  }

  h2 {
    font-size: 45px;
  }

  h3 {
    font-size: 33px;
  }

  h4 {
    font-size: 31px;
  }

  h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 999px) {
  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 29px;
  }

  h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 27px;
  }

  h4 {
    font-size: 25px;
  }


  h5 {
    font-size: 17px;
  }
}

.ant-input , .ant-input-sm, .ant-time-picker-input {
  font-size: 15px !important;
}

.full-background {
  background-image: url('assets/images/common/full-page-background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.half-background {
  background-image: url('assets/images/common/half-page-background.png');
  background-repeat: no-repeat;
  background-size: cover;
}
