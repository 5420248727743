.white-outline-btn {
    border-radius: 22px !important;;
    background-color: transparent !important;;
    color: white !important;;
    border: 1px solid white !important;;

    &:hover,
    &:active,
    &:focus {
        background-color: #262634 !important;;
        border: 1px solid white !important;;
    }
}
