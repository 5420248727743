.top-banner {
    &.home {
        background-color: rgb(30,30,38);
        height: 570px;
        background-size: cover;
        background-position: right;
        @media screen and (max-width: 1399px) {
            height: 450px;
        }
        @media screen and (max-width: 1199px) {
            height: 550px;
            margin-bottom: 10rem;
        }
        @media screen and (max-width: 767px) {
            height: 450px;
            margin-bottom: 12rem;
        }
        @media screen and (max-width: 575px) {
            height: 350px;
            margin-bottom: 13rem;
        }

         @media screen and (max-width: 400px) {
            height: 300px;
        }

        .top-banner-section {
            padding: 50px 0 80px 0;
            @media screen and (max-width: 1199px) {
                padding: 50px 0;
            }
        }

        .top-banner-wrapper {
            max-width: 700px;
            margin: 0 auto;

            &.img-wrapper {
                @media screen and (max-width: 1199px) {
                    margin: 0 auto 70px auto;
                }
            }
        }
    }

    &.about {
        height: 550px;
        background-size: cover;
        background-position: right;
        @media screen and (max-width: 1499px) {
            height: 500px;
        }
        @media screen and (max-width: 1399px) {
            height: 450px;
        }
        @media screen and (max-width: 1199px) {
            height: 400px;
        }
        @media screen and (max-width: 767px) {
            height: 400px;
        }
        @media screen and (max-width: 575px) {
            height: 350px;
        }

        &::before {
            @media screen and (max-width: 1199px) {
                content: "";
                position: absolute;
                top: 0;
                width: 100%;
                height: inherit;
                background-color: rgba(0,0,0,.1);
            }
        }

        .top-banner-section {
            padding: 150px 0;
            @media screen and (max-width: 1199px) {
                padding: 120px 0;
            }
            @media screen and (max-width: 575px) {
                padding: 80px 0;
            }
        }

        .top-banner-wrapper {
            max-width: 500px;
            margin: 0 auto;
            @media screen and (max-width: 1399px) {
                max-width: 400px;
            }
            @media screen and (max-width: 1199px) {
                margin: unset;
            }
            @media screen and (max-width: 991px) {
                max-width: unset;
            }
        }
    }
}

// Start: Top Banner Pattern
.pattern-1 {
    bottom: 25%;
    left: 5%;
    @media screen and (max-width: 1399px) {
        bottom: 0%;
        left: 25%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-2 {
    top: 15%;
    left: 27%;
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-3 {
    top: 27%;
    left: 44%;
    @media screen and (max-width: 1399px) {
        top: 31%;
        left: 39%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-4 {
    bottom: 20%;
    left: 38%;
    @media screen and (max-width: 1399px) {
        bottom: 15%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-5 {
    bottom: 9%;
    right: 7%;
    @media screen and (max-width: 1399px) {
        display: none;
    }
}

.pattern-6 {
    bottom: 34%;
    left: 11%;
    @media screen and (max-width: 1399px) {
        bottom: 14%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-7 {
    bottom: 22%;
    left: 43%;
    @media screen and (max-width: 1499px) {
        left: 37%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-8 {
    top: 18%;
    left: 36%;
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-9 {
    bottom: 0%;
    right: 7%;
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-10 {
    top: 25%;
    left: 17%;
    @media screen and (max-width: 1299px) {
        left: 7%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.pattern-11 {
    top: 14%;
    right: 25%;
    @media screen and (max-width: 1299px) {
        right: 15%;
    }
    @media screen and (max-width: 1199px) {
        display: none;
    }
}
// End: Top Banner Pattern

.our-websites-wrapper {
    padding: 50px 0;
    background: $black-1;

    .icon-wrapper {
        width: 220px;
        margin: 0 auto;
        padding: 2rem 0;
        text-align: center;

        img {
            width: 180px !important;
            margin: 0 auto;
        }

        span {
            color: $black-7 !important;
            text-decoration: underline;
            margin-top: 1rem;
            display: block;
        }
    }
}

.body {
    font-family: "Roboto", "-apple-system", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
}
