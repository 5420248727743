.treasurynow-tabset-wrapper {
  display: flex;
  justify-content: center;

  .ant-tabs-nav {
    .ant-tabs-tab {
      font-size: 22px !important;
      font-weight: 600 !important;
      padding: 0.75rem 0.5rem !important;

      @media screen and (max-width: 999px) {
        font-size: 18px !important;
      }
    }
    .ant-tabs-tab:first-child {
      background: linear-gradient(90deg, #70CE91, #119645);
      -webkit-background-clip: text;
              background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .ant-tabs-tab:last-child {
      background: linear-gradient(90deg, #73C1F8, #0079D0);
      -webkit-background-clip: text;
              background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .ant-tabs-ink-bar {
      height: 2.7px;
    }
  }

  .client-tab-bar {
    .ant-tabs-nav .ant-tabs-ink-bar {
      background: linear-gradient(90deg, #70CE91, #119645);
    }
  }

  .business-tab-bar {
    .ant-tabs-nav .ant-tabs-ink-bar {
      background: linear-gradient(90deg, #73C1F8, #0079D0);
    }
  }
}
