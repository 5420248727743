  .ant-modal .ant-modal-content {
    background: white !important;
    box-shadow: none !important;

    .ant-modal-close {
      z-index: 10;
      line-height: 1;
      color: #fff;
      .ant-modal-close-x {
        font-size: 20px;
      }
    }

    .ant-modal-header {
      padding: 1.3rem 0 0.3rem!important;
      background: transparent !important;
      border-bottom: none!important;
    }

    .ant-modal-body {
      background: transparent !important;
    }

  }

  .ui-pop-up-banner.ant-modal .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
  }
  .ant-modal-body {
    background: transparent !important;
  }
