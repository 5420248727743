.ui-steps {
  .ant-steps {
    .ant-steps-item-tail {
      left: 26px !important;
    }
    .ant-steps-item-icon {
      box-shadow: 0px 0px 15px #FFFFFF29 !important;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 25px;
    }

    .ant-steps-item-title {
      font-size: 22px;
      color: white !important;
      min-height: 65px;
      display: inline-flex;
      align-items: center;
      line-height: 27px;

      @media screen and (max-width: 991px) {
        font-size: 19px;
        top: -6px;
      }
    }
  }
}

.ui-steps-milestones {
  .ant-steps {
    .ant-steps-item-tail {
      margin-left: 15px;
      top: 14px;

      &:after {
        height: 5px;
        background: #03A7D1 !important;
        @media screen and (max-width: 767px) {
          width: 300px;
          margin-left: 2px;
          width: 300px;
        }
      }
    }

    .ant-steps-item-icon {
      background-color: #03A7D1 !important;

      width: 30px;
      height: 30px;
      margin-left: 0;

      @media screen and (max-width: 767px) {
        width: 20px !important;
        height: 20px !important;
      }

      .ant-steps-icon-dot {
        background: #03A7D1 !important;
      }
    }

    .ant-steps-item-tail {
      @media screen and (max-width: 767px) {
        margin-right: 12px !important;
        top: 10px !important;
      }
    }

    .ant-steps-icon {
      display: none;
    }

    .ant-steps-item-content {
      width: 270px;
      padding-right: 10px;
      text-align: left;
    }

    .ant-steps-item-title {
      font-size: 23px;
      color: #03A7D1 !important;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .ant-steps-item-description {
      font-size: 16px;
      color: #F3F3F3 !important;
    }
  }
}
