.ant-select-dropdown {
    background-color: #222D3F;
}

.ant-select-item {
    color: #CAD0D7;
    font-size: 15px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: unset !important;
    color: white !important;
}

.ant-select-item-option-selected {
    background-color: unset !important;
    color: white !important;
}


.ui-select {
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border-color: transparent;
    height: 55px;
    padding: 10px 11px;
  }
}
