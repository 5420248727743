.header-dropdown {
    min-width: 270px;

    a {
        color: #CAD0D7;
        font-size: 15px;
        &:hover {
            text-decoration: underline;
            color: white;
            font-weight: 500;
        }
    }

    .ant-dropdown-menu {
        padding: 10px 0;
        .ant-dropdown-menu-item {
            padding: 0.3rem 1.25rem;
        }
    
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover {
            background: none;
        }
    }
}