.animated {
    -webkit-animation-duration: 2s !important;
    animation-duration: 2s !important;
    visibility: visible !important;

    &.animation-1s {
        -webkit-animation-duration: 1s !important;
        animation-duration: 1s !important;
    }

    &.animation-2s {
        -webkit-animation-duration: 2s !important;
        animation-duration: 2s !important;
    }
}

.hide-on-init {
    visibility: hidden;
}

.animation-delay-1s {
    animation-delay: 1s;
}

.animation-delay-0-2s {
    animation-delay: 0.2s;
}

.animation-delay-0-3s {
    animation-delay: 0.2s;
}

.animation-delay-0-4s {
    animation-delay: 0.4s;
}

.animation-delay-0-6s {
    animation-delay: 0.6s;
}

.animation-delay-0-8s {
    animation-delay: 0.8s;
}

.animation-delay-0-9s {
    animation-delay: 0.9s;
}

.animation-delay-1-2s {
    animation-delay: 1.2s;
}

.animation-delay-1-6s {
    animation-delay: 1.6s;
}

@keyframes growFromTop {
    from {
        transform: scale(1, .7);
    }
    to {
        transform: scale(1, 1);
    }
}

@keyframes growFromCenter {
    from {
        transform: scale(.7, 1);
    }
    to {
        transform: scale(1, 1);
    }
}

@keyframes growFromCenterVertically {
    from {
        transform: scale(1, .3);
    }
    to {
        transform: scale(1, 1);
    }
}

@keyframes growFromLeft {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleY(1);
    }
}